<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="25" viewBox="0 0 28 25" fill="none">
        <path d="M18.1609 7.55286H9.625V9.90008H18.1609V7.55286Z" fill="white" />
        <path d="M18.1609 11.0751H9.625V13.4223H18.1609V11.0751Z" fill="white" />
        <path
            d="M27.9251 7.05277L13.9684 0L0 6.55277L1.26611 8.95555L3.53333 7.89166V18.2694H24.3358V8.28611L26.5795 9.41944L27.9221 7.05277H27.9251ZM21.4944 15.5833H6.38061V6.55555L13.9184 3.01944L21.4944 6.84722V15.5833Z"
            fill="white" />
        <path
            d="M3.25635 21.5973V19.1862H4.5784V19.5945H3.61851V20.1278H4.51362V20.5362H3.61851V21.1862H4.61374V21.5945H3.25929L3.25635 21.5973Z"
            fill="white" />
        <path
            d="M6.29515 21.2889C6.38349 21.375 6.48065 21.4417 6.58371 21.4917L6.45121 21.8389C6.39821 21.8167 6.34226 21.7889 6.29221 21.75C6.28043 21.7417 6.20093 21.6722 6.05371 21.5389C5.93593 21.6083 5.80638 21.6417 5.66504 21.6417C5.39121 21.6417 5.17626 21.5333 5.02021 21.3139C4.86415 21.0944 4.7876 20.7889 4.7876 20.3944C4.7876 20 4.86415 19.6944 5.02021 19.475C5.17626 19.2556 5.38532 19.1472 5.65326 19.1472C5.92121 19.1472 6.12732 19.2556 6.28337 19.475C6.43943 19.6917 6.51599 19.9972 6.51599 20.3917C6.51599 20.6 6.49537 20.7833 6.45121 20.9389C6.41882 21.0583 6.36582 21.175 6.29515 21.2889ZM6.0066 21.0222C6.05371 20.9472 6.0861 20.8583 6.10965 20.7555C6.13321 20.65 6.14499 20.5305 6.14499 20.3972C6.14499 20.1194 6.10082 19.9083 6.00954 19.7722C5.91826 19.6333 5.80049 19.5667 5.65326 19.5667C5.50604 19.5667 5.38826 19.6361 5.29699 19.775C5.20571 19.9139 5.16154 20.1222 5.16154 20.4C5.16154 20.6778 5.20571 20.8944 5.29699 21.0333C5.38826 21.1722 5.5031 21.2444 5.64149 21.2444C5.69154 21.2444 5.7416 21.2333 5.78871 21.2111C5.7151 21.1472 5.64149 21.0972 5.56493 21.0611L5.66504 20.7778C5.78282 20.8333 5.89471 20.9167 6.00365 21.025L6.0066 21.0222Z"
            fill="white" />
        <path
            d="M6.75441 19.1861H7.11658V20.4972C7.11658 20.7056 7.11952 20.8417 7.12835 20.9028C7.14602 21.0028 7.18135 21.0806 7.2373 21.1417C7.29324 21.2028 7.37274 21.2306 7.47285 21.2306C7.57296 21.2306 7.64952 21.2028 7.70252 21.1445C7.75258 21.0889 7.78496 21.0167 7.7938 20.9361C7.80263 20.8556 7.80852 20.7167 7.80852 20.525V19.1861H8.17069V20.4556C8.17069 20.7472 8.16185 20.95 8.14124 21.0695C8.12063 21.1889 8.0853 21.2917 8.0323 21.3722C7.9793 21.4528 7.91158 21.5195 7.82324 21.5695C7.73785 21.6195 7.62302 21.6417 7.48169 21.6417C7.31385 21.6417 7.1843 21.6139 7.09596 21.5611C7.00763 21.5084 6.93991 21.4389 6.88691 21.3528C6.83685 21.2667 6.80152 21.1778 6.7868 21.0861C6.76324 20.9472 6.75146 20.7445 6.75146 20.4722V19.1833L6.75441 19.1861Z"
            fill="white" />
        <path
            d="M10.1052 21.5973H9.71944L9.56633 21.0528H8.84788L8.70066 21.5973H8.31494L9.001 19.1862H9.38377L10.1022 21.5973H10.1052ZM9.44855 20.6445L9.19533 19.7473L8.95683 20.6445H9.44855Z"
            fill="white" />
        <path d="M10.2554 21.5972V19.2056H10.6175V21.1861H11.5126V21.5945H10.2583L10.2554 21.5972Z" fill="white" />
        <path
            d="M13.3296 21.5973V19.1862H13.6918V20.1362H14.3984V19.1862H14.7606V21.5973H14.3984V20.5445H13.6918V21.5973H13.3296Z"
            fill="white" />
        <path
            d="M14.999 20.4111C14.999 20.1667 15.0255 19.9584 15.0785 19.7917C15.1197 19.6695 15.1757 19.5584 15.2434 19.4611C15.3141 19.3639 15.3906 19.2917 15.476 19.2445C15.5879 19.1806 15.7145 19.15 15.8617 19.15C16.1238 19.15 16.3329 19.2611 16.4919 19.4806C16.6479 19.7 16.7274 20.0084 16.7274 20.4C16.7274 20.7917 16.6479 21.0945 16.4919 21.3139C16.3358 21.5334 16.1267 21.6445 15.8647 21.6445C15.6026 21.6445 15.3906 21.5361 15.2346 21.3167C15.0785 21.0972 14.999 20.7972 14.999 20.4111ZM15.37 20.3945C15.37 20.6695 15.4171 20.875 15.5084 21.0167C15.5997 21.1584 15.7204 21.2278 15.8617 21.2278C16.0031 21.2278 16.1209 21.1584 16.2121 21.0167C16.3034 20.8778 16.3505 20.6667 16.3505 20.3861C16.3505 20.1056 16.3064 19.9028 16.2151 19.7667C16.1238 19.6306 16.006 19.5611 15.8588 19.5611C15.7116 19.5611 15.5909 19.6306 15.4996 19.7695C15.4083 19.9084 15.3641 20.1167 15.3641 20.3945H15.37Z"
            fill="white" />
        <path
            d="M16.9658 19.1861H17.328V20.4972C17.328 20.7056 17.3309 20.8417 17.3398 20.9028C17.3574 21.0028 17.3928 21.0806 17.4487 21.1417C17.5047 21.2028 17.5842 21.2306 17.6843 21.2306C17.7844 21.2306 17.8609 21.2028 17.9139 21.1445C17.964 21.0889 17.9964 21.0167 18.0052 20.9361C18.0141 20.8528 18.0199 20.7167 18.0199 20.525V19.1861H18.3821V20.4556C18.3821 20.7472 18.3733 20.95 18.3527 21.0695C18.3321 21.1889 18.2967 21.2917 18.2437 21.3722C18.1907 21.4556 18.123 21.5195 18.0347 21.5695C17.9493 21.6195 17.8344 21.6417 17.6931 21.6417C17.5253 21.6417 17.3957 21.6139 17.3074 21.5611C17.2191 21.5084 17.1484 21.4389 17.0983 21.3528C17.0483 21.2667 17.0129 21.1778 16.9982 21.0861C16.9747 20.9472 16.9629 20.7445 16.9629 20.4722V19.1833L16.9658 19.1861Z"
            fill="white" />
        <path
            d="M18.6177 20.8167L18.9681 20.7694C18.9887 20.9278 19.0299 21.0444 19.0947 21.1194C19.1595 21.1944 19.2448 21.2305 19.3567 21.2305C19.4686 21.2305 19.5599 21.1972 19.6188 21.1305C19.6777 21.0639 19.7071 20.9861 19.7071 20.8972C19.7071 20.8417 19.6953 20.7917 19.6688 20.7528C19.6453 20.7139 19.6011 20.6778 19.5393 20.6472C19.4981 20.6278 19.4009 20.5917 19.2537 20.5417C19.0593 20.4778 18.9239 20.3972 18.8473 20.3028C18.7384 20.1694 18.6854 20.0083 18.6854 19.8194C18.6854 19.6972 18.7119 19.5833 18.762 19.475C18.815 19.3694 18.8886 19.2861 18.9857 19.2305C19.0829 19.175 19.2007 19.1472 19.3361 19.1472C19.5599 19.1472 19.7277 19.2139 19.8396 19.3472C19.9515 19.4806 20.0133 19.6583 20.0192 19.8805L19.6571 19.8972C19.6423 19.775 19.61 19.6861 19.557 19.6333C19.5069 19.5805 19.4303 19.5528 19.3302 19.5528C19.2301 19.5528 19.1418 19.5805 19.0829 19.6389C19.0446 19.675 19.027 19.725 19.027 19.7889C19.027 19.8444 19.0446 19.8944 19.08 19.9333C19.1241 19.9833 19.236 20.0389 19.4097 20.0944C19.5835 20.15 19.716 20.2083 19.7984 20.2694C19.8838 20.3305 19.9486 20.4111 19.9957 20.5139C20.0428 20.6167 20.0663 20.7472 20.0663 20.8972C20.0663 21.0361 20.0369 21.1667 19.981 21.2861C19.925 21.4083 19.8426 21.4972 19.7395 21.5555C19.6365 21.6139 19.5069 21.6444 19.3508 21.6444C19.1241 21.6444 18.9533 21.575 18.8326 21.4333C18.7119 21.2917 18.6383 21.0861 18.6177 20.8167Z"
        fill="white" />
    <path d="M20.3105 21.5973V19.1862H20.6727V21.5973H20.3105Z" fill="white" />
    <path
        d="M20.9644 21.5973V19.1862H21.3147L22.0479 20.8112V19.1862H22.3806V21.5973H22.0214L21.2971 20.0056V21.5973H20.9644Z"
        fill="white" />
    <path
        d="M23.5406 20.7167V20.3083H24.3121V21.2722C24.2355 21.3694 24.1266 21.4583 23.9852 21.5305C23.841 21.6055 23.6967 21.6417 23.5495 21.6417C23.364 21.6417 23.202 21.5889 23.0636 21.4833C22.9252 21.3778 22.8192 21.225 22.7515 21.0278C22.6808 20.8305 22.6455 20.6167 22.6455 20.3861C22.6455 20.1361 22.6838 19.9111 22.7633 19.7167C22.8398 19.5222 22.9547 19.3722 23.1048 19.2667C23.2197 19.1861 23.361 19.1472 23.5318 19.1472C23.7526 19.1472 23.9263 19.2111 24.05 19.3361C24.1737 19.4611 24.2532 19.6333 24.2915 19.8555L23.9293 19.9444C23.9057 19.825 23.8586 19.7333 23.788 19.6639C23.7173 19.5944 23.6319 19.5611 23.5288 19.5611C23.3728 19.5611 23.2491 19.6278 23.1549 19.7639C23.0636 19.9 23.0165 20.1 23.0165 20.3667C23.0165 20.6528 23.0636 20.8694 23.1578 21.0139C23.2521 21.1583 23.3757 21.2305 23.5288 21.2305C23.6054 21.2305 23.679 21.2111 23.7556 21.1694C23.8321 21.1278 23.8969 21.0805 23.9499 21.0222V20.7139H23.5377L23.5406 20.7167Z"
        fill="white" />
    <path
        d="M3.61574 23.7667C3.61574 23.5223 3.64518 23.3139 3.70702 23.1473C3.75413 23.0251 3.81596 22.9139 3.8984 22.8167C3.98085 22.7195 4.06624 22.6473 4.16046 22.6001C4.28707 22.5362 4.43429 22.5056 4.59918 22.5056C4.89951 22.5056 5.14096 22.6167 5.32057 22.8362C5.50018 23.0556 5.59146 23.3639 5.59146 23.7556C5.59146 24.1473 5.50312 24.4501 5.32351 24.6695C5.1439 24.8889 4.9054 25.0001 4.60507 25.0001C4.30474 25.0001 4.06035 24.8917 3.88074 24.6723C3.70113 24.4528 3.61279 24.1528 3.61279 23.7667H3.61574ZM4.03679 23.7501C4.03679 24.0251 4.08979 24.2306 4.19579 24.3723C4.30179 24.5139 4.44018 24.5834 4.60507 24.5834C4.76996 24.5834 4.9054 24.5139 5.0114 24.3723C5.1174 24.2334 5.1704 24.0223 5.1704 23.7417C5.1704 23.4612 5.1174 23.2584 5.01435 23.1223C4.91129 22.9862 4.77585 22.9167 4.60507 22.9167C4.43429 22.9167 4.2959 22.9862 4.19285 23.1251C4.08685 23.2639 4.03679 23.4723 4.03679 23.7501Z"
        fill="white" />
    <path
        d="M5.9126 24.9527V22.5416H6.5751C6.82832 22.5416 6.99026 22.5527 7.06682 22.5777C7.1846 22.6138 7.28176 22.6916 7.36126 22.8138C7.44076 22.9361 7.47904 23.0916 7.47904 23.2833C7.47904 23.4305 7.45549 23.5555 7.41132 23.6555C7.36421 23.7555 7.30826 23.8361 7.2376 23.8916C7.16693 23.95 7.09626 23.9861 7.02265 24.0055C6.92549 24.0277 6.78121 24.0388 6.59276 24.0388H6.32482V24.95H5.9126V24.9527ZM6.32482 22.95V23.6305H6.55154C6.71349 23.6305 6.82538 23.6194 6.87838 23.5916C6.93432 23.5666 6.97849 23.5277 7.00793 23.475C7.04032 23.4222 7.05504 23.3583 7.05504 23.2888C7.05504 23.2027 7.03149 23.1305 6.98732 23.0722C6.94315 23.0166 6.88721 22.9805 6.81949 22.9666C6.76943 22.9555 6.66932 22.95 6.51915 22.95H6.31893H6.32482Z"
        fill="white" />
    <path
        d="M7.81152 24.9527V22.5416H8.47402C8.72725 22.5416 8.88919 22.5527 8.96574 22.5777C9.08352 22.6138 9.18069 22.6916 9.26019 22.8138C9.33969 22.9361 9.37797 23.0916 9.37797 23.2833C9.37797 23.4305 9.35441 23.5555 9.31024 23.6555C9.26008 23.7555 9.20719 23.8361 9.13652 23.8916C9.06586 23.95 8.99519 23.9861 8.92158 24.0055C8.82441 24.0277 8.68013 24.0388 8.49169 24.0388H8.22374V24.95H7.81152V24.9527ZM8.22374 22.95V23.6305H8.45047C8.61241 23.6305 8.7243 23.6194 8.7773 23.5916C8.83325 23.5666 8.87741 23.5277 8.90686 23.475C8.93924 23.4222 8.95397 23.3583 8.95397 23.2888C8.95397 23.2027 8.93336 23.1305 8.88919 23.0722C8.84502 23.0166 8.78908 22.9805 8.72136 22.9666C8.6713 22.9555 8.57119 22.95 8.42102 22.95H8.2208H8.22374Z"
        fill="white" />
    <path
        d="M9.62502 23.7667C9.62502 23.5223 9.65446 23.3139 9.71629 23.1473C9.7634 23.0251 9.82524 22.9139 9.90768 22.8167C9.99013 22.7195 10.0755 22.6473 10.1697 22.6001C10.2963 22.5362 10.4436 22.5056 10.6085 22.5056C10.9088 22.5056 11.1502 22.6167 11.3298 22.8362C11.5095 23.0556 11.6007 23.3639 11.6007 23.7556C11.6007 24.1473 11.5124 24.4501 11.3328 24.6695C11.1532 24.8889 10.9147 25.0001 10.6143 25.0001C10.314 25.0001 10.0696 24.8917 9.89001 24.6723C9.7104 24.4528 9.62207 24.1528 9.62207 23.7667H9.62502ZM10.0461 23.7501C10.0461 24.0251 10.0991 24.2306 10.2051 24.3723C10.3111 24.5139 10.4495 24.5834 10.6143 24.5834C10.7792 24.5834 10.9147 24.5139 11.0207 24.3723C11.1267 24.2334 11.1797 24.0223 11.1797 23.7417C11.1797 23.4612 11.1267 23.2584 11.0236 23.1223C10.9206 22.9862 10.7851 22.9167 10.6143 22.9167C10.4436 22.9167 10.3052 22.9862 10.2021 23.1251C10.0961 23.2639 10.0461 23.4723 10.0461 23.7501Z"
        fill="white" />
    <path
        d="M11.9219 24.9527V22.5416H12.7876C13.0054 22.5416 13.1644 22.5638 13.2616 22.6055C13.3617 22.65 13.4383 22.725 13.5001 22.8361C13.559 22.9472 13.5884 23.075 13.5884 23.2166C13.5884 23.3972 13.5443 23.5472 13.4559 23.6638C13.3676 23.7805 13.2322 23.8555 13.0555 23.8861C13.1438 23.9472 13.2174 24.0138 13.2734 24.0888C13.3323 24.1611 13.4088 24.2916 13.506 24.4805L13.7533 24.95H13.2616L12.9672 24.425C12.8612 24.2388 12.7876 24.1194 12.7493 24.0722C12.711 24.0222 12.6698 23.9888 12.6256 23.9722C12.5814 23.9555 12.5137 23.9444 12.4166 23.9444H12.3312V24.95H11.9189L11.9219 24.9527ZM12.3341 23.5638H12.6403C12.8376 23.5638 12.9613 23.5527 13.0113 23.5333C13.0614 23.5138 13.0997 23.4805 13.1262 23.4305C13.1527 23.3833 13.1674 23.3222 13.1674 23.25C13.1674 23.1694 13.1497 23.1027 13.1114 23.0527C13.0732 23.0027 13.0231 22.9722 12.9554 22.9583C12.923 22.9527 12.8199 22.95 12.6521 22.95H12.3312V23.5638H12.3341Z"
        fill="white" />
    <path d="M14.4366 24.9527V22.95H13.8359V22.5416H15.4465V22.95H14.8459V24.9527H14.4337H14.4366Z" fill="white" />
    <path
        d="M15.7144 22.5417H16.1266V23.8528C16.1266 24.0612 16.1325 24.1973 16.1413 24.2584C16.159 24.3584 16.2002 24.4362 16.2679 24.4973C16.3327 24.5584 16.424 24.5862 16.5388 24.5862C16.6536 24.5862 16.742 24.5584 16.8009 24.5C16.8597 24.4445 16.8951 24.3723 16.9069 24.2917C16.9186 24.2112 16.9245 24.0723 16.9245 23.8806V22.5417H17.3367V23.8112C17.3367 24.1028 17.325 24.3056 17.3044 24.425C17.2837 24.5445 17.2425 24.6473 17.1807 24.7278C17.1218 24.8112 17.0423 24.875 16.9422 24.925C16.8421 24.975 16.7125 24.9973 16.5506 24.9973C16.3562 24.9973 16.209 24.9695 16.1089 24.9167C16.0088 24.8639 15.9293 24.7945 15.8704 24.7084C15.8115 24.6223 15.7732 24.5334 15.7556 24.4417C15.7291 24.3028 15.7144 24.1 15.7144 23.8278V22.5389V22.5417Z"
        fill="white" />
    <path
        d="M17.7666 24.9527V22.5416H18.167L19.0062 24.1555V22.5416H19.389V24.9527H18.9738L18.1494 23.3638V24.9527H17.7666Z"
        fill="white" />
    <path d="M19.8101 24.9527V22.5416H20.2223V24.9527H19.8101Z" fill="white" />
    <path d="M21.0733 24.9527V22.95H20.4727V22.5416H22.0833V22.95H21.4826V24.9527H21.0704H21.0733Z" fill="white" />
    <path
        d="M22.887 24.9527V23.9388L22.1392 22.5416H22.622L23.102 23.4972L23.5702 22.5416H24.0442L23.2963 23.9416V24.9527H22.8841H22.887Z"
        fill="white" />
</svg></template>