<!-- eslint-disable -->
<script setup>

</script>

<template>
    <div class="privacy-policy">
        <h1>Our Privacy Policy</h1>
        <h4>
            Introduction
        </h4>
        <p>
            Dyverse Marketing Group, Inc. (referred to herein as “Dyverse”, “we”, “our”, or “us”) and its affiliates
            recognizes the importance of protecting the personal information we may collect from customers, users and any
            other individual or entity (“Users”, “you”, or “your”). This Privacy Policy applies to data collection and
            privacy practices of Dyverse and when we collect personal information through your use of the website,
            www.dyverse.com, and other Dyverse-related sites, software and applications accessible on or by any top-level
            Dyverse domain owned by us (each, a “Site” and collectively the “Sites”), through our social media pages that we
            control, and through email messages that we may send to you, (collectively, the “Services”). Please note that
            this Privacy Policy excludes services that state that they are offered under a different privacy policy.
        </p>
        <h4>
            When this Privacy Policy Applies
        </h4>
        <p>
            Our Privacy Policy applies to all of the services offered by Dyverse and its affiliates and services offered on
            other sites, but excludes services that have separate privacy policies that do not incorporate this Privacy
            Policy.
        </p>
        <p>
            Our Privacy Policy does not apply to services offered by other companies or individuals, including products or
            sites that may be displayed to you, or other sites linked from our Services. Our Privacy Policy does not cover
            the information practices of other companies and organizations who advertise our Services, and who may use
            cookies, pixel tags and other technologies to serve and offer relevant ads.
        </p>
        <h4>
            Information We Collect
        </h4>
        <p>
            We collect information, including personal information, to provide better Services to all our customers and
            users. We use the term “Personal Information” to refer to any information that identifies or can be used to
            identify you. Common examples of Personal Information include: full name, email address, information about your
            device, and certain metadata.
        </p>
        <p>
            The Personal Information which we collect includes, but is not limited to, the following circumstances and data
            elements:
        </p>
        <ul>
            <li>If you express an interest in obtaining additional information about our Services, request customer support,
                use the "Contact Us" or similar features on our Site, register to use our Sites or Services, or download
                certain content, we may require that you provide to us your contact information, such as your name, email,
                phone number, zip or postal code, and user name and password, by completing forms on our website.</li>
            <li>
                To process payments, you may provide us with certain personal information such as the first and last name of
                the payment card owner, credit card billing address, payment card number, payment card expiration date, bank
                account information and routing numbers, phone numbers and email.
            </li>
            <li>
                If you report a problem or have a question about our Services, you may provide us with contact information,
                such as a phone number or email address;
            </li>
            <li>
                If you use and interact with our Sites or emails, we automatically collect information about your device and
                your usage of our websites or emails through cookies, web beacons or similar technologies, such as Internet
                Protocol (IP) addresses or other identifiers, which may qualify as Personal Information under applicable
                law;
            </li>
            <li>
                If you voluntarily submit certain information to our Services, such as filling out a survey about your
                experience, we collect the information you have provided as part of that request; and
            </li>
            <li>
                If you have applied for employment with us, you might be required to pass a background check, which includes
                providing us with information about your past employment history, any professional disciplinary records,
                criminal records, academic background and submit to drug testing.
            </li>
        </ul>
        <p>
            If you provide us or our service providers with any Personal Information relating to other individuals, you
            represent that you have the authority to do so and acknowledge that it will be used in accordance with this
            Privacy Popcy. If you believe that your Personal Information has been provided to us improperly, or to
            otherwise exercise your rights relating to your Personal Information, please contact us by using the information
            set out in the “How to Contact Us” section below.
        </p>
        <h4>
            Information Collected Related to California Residents
        </h4>
        <p>
            This Privacy Notice for California residents serves to inform California residents regarding the Personal
            Information that Dyverse Marketing Group, Inc. and its affiliates (collectively, “Dyverse,” “we,” “us,” or
            “our”) collects and applies solely to guests, visitors, users, and others who reside in the State of California
            (“consumers” or “you”). This Privacy Notice applies to Personal Information collected by Dyverse through your
            use of the website, www.dyverse.com, and other Dyverse-related sites, software and applications accessible on or
            by any top-level Dyverse domain owned by us (each, a “Site” and collectively the “Sites”), through our social
            media pages that we control, through email messages that we may send to you, and when you visit or stay as a
            guest at one of our properties or purchase related services (collectively, the “Services”). We adopt this notice
            to comply with the California Consumer Privacy Act of 2018 (“CCPA”) and other California privacy laws. Any terms
            defined in the CCPA have the same meaning when used in this notice.
        </p>
        <h4>
            Consent
        </h4>
        <p>
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        </p>
        <h4>
            How We Use Your Information
        </h4>
        <p>
            We use the information we collect in various ways, including to:
        </p>
        <ul>
            <li>
                Provide, operate, and maintain our website
            </li>
            <li>
                Improve, personalize, and expand our website
            </li>
            <li>
                Understand and analyze how you use our website
            </li>
            <li>
                Develop new products, services, features, and functionality
            </li>
            <li>
                Communicate with you, either directly or through one of our agents
            </li>
            <li>
                Send you emails
            </li>
            <li>
                Find and prevent fraud
            </li>
        </ul>
        <h4>
            Children's Information
        </h4>
        <p>
            Another part of our priority is adding protection for children while using the internet. We encourage
            parents and guardians to observe, participate in, and/or monitor and guide their online activity.
        </p>
        <p>
            Dyverse and our affiliates do not knowingly collect any Personal Identifiable Information from children
            under the age of 13. If you think that your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such
            information from our records.
        </p>
    </div>
</template>

<style lang="scss" scoped>
h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 35px;
    line-height: 1.5;
    color: #111827;
    margin-bottom: 1rem;
}
.privacy-policy {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
h4 {
    margin-bottom: 1rem;
    font-size: 18px;
    line-height: 1.5;
    color: #111827;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
}

p {
    margin-bottom: 1rem;
    font-size: 16px;
    line-height: 1.5;
    color: #111827;
    font-family: 'Lato', sans-serif;

}
ul {
    margin-bottom: 1rem;
    font-size: 16px;
    line-height: 1.5;
    color: #111827;
    list-style-type: disc;
    padding-left: 20px;
    font-family: 'Lato', sans-serif;
    text-align: left;
    li {
        margin-bottom: 0.5rem;
        font-size: 16px;
        line-height: 1.5;
        color: #111827;
        list-style-type: disc;
        padding-left: 20px;
        font-family: 'Lato', sans-serif;

        &:last-child { 
            margin-bottom: 0;
        }
    }
}
</style>

  