<script setup>
import { defineEmits, defineProps } from 'vue';
const emit = defineEmits(['click']);

function onClick(event) {
    emit('click', event);
}

defineProps({
    text: String,
    icon: Object
});
</script>

<template>
    <button class="action-button" @click="onClick">
        {{ text }}
        <span v-if="icon" class="icon">
            <!-- Using a dynamic component to render the correct icon -->
            <component :is="icon"></component>
        </span>
    </button>
</template>
  
<style scoped lang="scss">
.action-button {
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 30px;
    border: none;
    border-radius: 50px;
    background-color: #a35027;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Desktop Btn Copy */
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    .icon {
        display: flex;
        align-items: center;
        margin-left: 8px;

        svg {
            width: 20px;
        }
    }

    .text {
        display: flex;
        align-items: center;
        

    }

    &:hover {
        background-color: darken(#C47C5A, 10%);
    }

}
</style>
  