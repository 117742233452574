<script setup>
import { defineProps, onMounted, ref } from 'vue';
import AppButton from '@/components/ui/AppButton.vue';
import IconShareOffer from '@/components/ui/icons/IconShareOffer.vue';

const props = defineProps({
  offer: {
    type: Object,
    required: true,
  }
});

const copied = ref(false);

onMounted(() => {
  if (window.location.hash && window.location.hash === `#Offer-${props.offer.id}`) {
    const element = document.querySelector(window.location.hash);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
});

const handleShareClick = () => {
  const offerUrl = `${window.location.origin}${window.location.pathname}#Offer-${props.offer.id}`;
  navigator.clipboard.writeText(offerUrl)
    .then(() => {
      copied.value = true;
      setTimeout(() => copied.value = false, 2000); // Reset after 2 seconds
    })
    .catch(err => {
      console.error('Failed to copy: ', err);
    });
};

const beforeEnter = () => {
  // Adjust the style or state before the text enters
};

const afterLeave = () => {
  // Adjust the style or state after the icon leaves
  setTimeout(() => copied.value = false, 2000); // Delay resetting the 'copied' state
};

// Button links
const handleActionClick = (action) => {
  // Open the link in a new tab
  if (action.link) {
    window.open(action.link, '_blank');
  } else {
    console.log('Action clicked:', action);
  }
};
</script>

<template>
  <div class="offer-item">
    <a :name="'Offer-' + props.offer.id"></a>
    <div class="offer-image">
      <img :src="offer.imageUrl" :alt="offer.title" />
    </div>
    <div class="offer-header">
      <span class="offer-date-and-category">{{ offer.date }} <span>&bull;</span> {{ offer.category }}</span>
    </div>
    <h3 class="offer-title">{{ offer.title }}</h3>
    <p class="offer-description">{{ offer.description }}</p>
    <p class="offer-valid">{{ offer.offerValid }}</p>
    <div class="offer-actions">
      <div class="buttons">
        <AppButton v-for="action in offer.actions" :key="action.text" :text="action.text" :icon="action.icon" @click="handleActionClick(action)">
          <template #icon>
            <component :is="action.icon" />
          </template>
        </AppButton>
      </div>
      <div class="share-this-offer" @click="handleShareClick">
        <transition name="fade" @before-enter="beforeEnter" @after-leave="afterLeave">
          <IconShareOffer v-if="!copied" />
        </transition>
        <transition name="fade">
          <div v-if="copied" class="copied-text">Offer copied!</div>
        </transition>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.offer-item {
  max-width: 700px;
  margin: 0 auto; // This centers the offer item
  padding: 30px; // Adds spacing inside the offer item
  background-color: #fff; // Assuming a white background
  border-radius: 8px; // Adjust as needed
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Adds a subtle shadow
  margin-bottom: 30px; // Space between offer items

  .offer-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .offer-date-and-category {
      color: rgba(0, 0, 0, 0.50);

      /* Desktop Date + Category */
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      text-transform: uppercase;
      margin: 0;

      span {
        margin: 0 5px;
      }
    }

  }

  .offer-title {
    color: #000;

    /* Desktop H3 Bold */
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin: 5px 0;
  }

  .offer-image {
    width: 100%;
    margin-bottom: 15px;

    img {
      width: 100%;
      border-radius: 8px; // Adjust as needed
    }
  }

  .offer-description {
    color: #000;

    /* Desktop Offer Copy */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    margin: 5px 0;
  }

  .offer-valid {
    color: rgba(0, 0, 0, 0.70);

    /* Desktop Offer Valid */
    font-family: Lato;
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
    line-height: 25px;
  }

  .offer-actions {
    display: flex;
    gap: 10px;
    margin: 25px 0 0;
    align-items: center;
    justify-content: space-between;

    .buttons {
      display: flex;
      gap: 10px;

      // wrap the buttons on smaller screens
      @media (max-width: 660px) {
        flex-wrap: wrap;
      }

      .action-button {
        @media (max-width: 660px) {
          width: 100%;
        }
      }

    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.4s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
      display: none; // Hide element initially
    }

    .fade-enter-to,
    .fade-leave-from {
      opacity: 1;
      display: block; // Ensure the element is visible during transition
    }

    .share-this-offer {
      display: flex;
      font-family: 'Lato';
      font-size: 15px;
      cursor: pointer;
      transition: all 1s ease-in-out;

      @media (max-width: 660px) {
        display: none;
      }
    }
  }
}
</style>
