<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path
            d="M16.7494 9.09993C18.1577 9.09993 19.2994 7.95827 19.2994 6.54996C19.2994 5.14166 18.1577 4 16.7494 4C15.3411 4 14.1995 5.14166 14.1995 6.54996C14.1995 7.95827 15.3411 9.09993 16.7494 9.09993Z"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M6.54996 15.0495C7.95827 15.0495 9.09993 13.9079 9.09993 12.4995C9.09993 11.0912 7.95827 9.94958 6.54996 9.94958C5.14166 9.94958 4 11.0912 4 12.4995C4 13.9079 5.14166 15.0495 6.54996 15.0495Z"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M16.7494 21.0001C18.1577 21.0001 19.2994 19.8584 19.2994 18.4501C19.2994 17.0418 18.1577 15.9001 16.7494 15.9001C15.3411 15.9001 14.1995 17.0418 14.1995 18.4501C14.1995 19.8584 15.3411 21.0001 16.7494 21.0001Z"
        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.75122 13.7834L14.5566 17.1664" stroke="white" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    <path d="M14.5481 7.83289L8.75122 11.2158" stroke="white" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
</svg></template>