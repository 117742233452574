<template>
    <section class="hero">
        <div class="hero-content">
            <img src="@/assets/images/property-logo.png" alt="Property logo"
                class="hero-logo">
            <h1 class="hero-title">Lumi Offers</h1>
            <p class="hero-subtitle">Stay in the loop on our latest specials and offers.</p>
        </div>
    </section>
</template>
  
<script setup></script>
  
<style lang="scss" scoped>
.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
    background: #e3e0d2;

    .hero-content {
        text-align: center;
        max-width: 600px;
    }

    .hero-logo {
        width: 40%;
        padding-bottom: 25px;

        @media (max-width: 660px) {
            /* Mobile */
            bottom: 25px;
            width: 250px;
        }
    }

    .hero-title {
        color: #111827;
        /* Desktop H1 */
        font-family: Lato;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 49px;
        text-transform: capitalize;
        margin: 0;

        color: #111827;
        text-align: center;

        @media (max-width: 660px) {
            /* Mobile H1 */
            font-family: Lato;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
            text-transform: capitalize;
        }
    }

    .hero-subtitle {
        color: #111827;
        text-align: center;

        /* Desktop Hero Copy */
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px;
        margin: 10px 0;

        @media (max-width: 660px) {
            color: #111827;
            text-align: center;

            /* Mobile Hero Copy */
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 29px;
            margin: 5px 0
        }
    }

    // overall mobile hero adjustments
    @media (max-width: 660px) {
        padding: 0 40px;
        height: 250px;
    }
}
</style>
  