<script setup>
import { ref } from 'vue';
import AppButton from '@/components/ui/AppButton.vue';
import IconShare from '@/components/ui/icons/IconShare.vue';

const buttonText = ref('Share Offers');

function onShareClick() {
    navigator.clipboard.writeText(window.location.href)
        .then(() => {
            buttonText.value = 'Link Copied!';
            setTimeout(() => buttonText.value = 'Share Offers', 2000); // Reset text after 2 seconds
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
            // Handle error (optional)
        });
}
</script>

<template>
    <nav class="nav-bar">
        <h2 class="nav-title">Recent Offers</h2>
        <span v-if="shareMessage">{{ shareMessage }}</span>
        <AppButton :text="buttonText" :icon="IconShare" @click="onShareClick" />
    </nav>
</template>
  
<style scoped lang="scss">
.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent;

    .nav-title {
        color: #000;

        /* Desktop H2 */
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
    }

    span {
        font-family: 'Lato';
        color: #ffffff;
        font-size: 20px;
        font-weight: 600;
    }
}
</style>
  