// router/index.js
import { createRouter, createWebHistory } from 'vue-router'

// Import your route components here
import Feed from '@/views/Feed.vue'
import PrivacyPolicy from '@/views/Privacy-Policy.vue';

const routes = [
  {
    path: '/',
    redirect: '/feed'
  },
  {
    path: '/feed',
    name: 'Feed',
    component: Feed
  },
  {
    path: '/privacy-policy',
    name: 'Privacy-Policy',
    component: PrivacyPolicy
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
