<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <g clip-path="url(#clip0_140_1735)">
            <mask id="mask0_140_1735" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="19"
                height="19">
                <path d="M19 0H0V19H19V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_140_1735)">
                <path
                    d="M14.6583 1.66992H2.0427C1.2217 1.66992 0.559326 2.33507 0.559326 3.1533V5.37975H16.1445V3.1533C16.1417 2.33507 15.4766 1.66992 14.6583 1.66992Z"
                    stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.67358 2.78309V0.556641" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M13.0248 2.78309V0.556641" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M9.90771 2.78309V0.556641" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M6.79065 2.78309V0.556641" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M9.5376 7.88452H10.4644" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M6.23413 7.88452H7.16089" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M12.8383 7.88452H13.765" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M2.93054 10.3893H3.8573" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M6.23413 10.3893H7.16089" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M9.5376 10.3893H10.4644" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M2.93054 12.894H3.8573" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                    stroke-linejoin="round" />
            <path d="M6.23413 12.894H7.16089" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M18.4434 14.3607C18.4434 16.615 16.6149 18.4435 14.3606 18.4435C12.1063 18.4435 10.2778 16.615 10.2778 14.3607C10.2778 12.1064 12.1063 10.278 14.3606 10.278C16.6149 10.278 18.4434 12.1064 18.4434 14.3607Z"
                stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.3606 12.5044V14.3607H16.2169" stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M16.1418 10.6869V5.37964H0.556641V14.2855C0.556641 14.9005 1.05481 15.3987 1.66987 15.3987H10.4115"
                stroke="white" stroke-miterlimit="13.3333" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </g>
    <defs>
        <clipPath id="clip0_140_1735">
            <rect width="19" height="19" fill="white" />
        </clipPath>
    </defs>
</svg></template>