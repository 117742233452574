<script setup>
import { ref, markRaw, computed } from 'vue';
// components
import OfferItem from '@/components/offers/OfferItem.vue';
// icons
import IconContact from '@/components/ui/icons/IconContact.vue';
import IconAvailability from '@/components/ui/icons/IconAvailability.vue';
import IconTour from '@/components/ui/icons/IconTour.vue';
// images
import image1 from '@/assets/images/offer-photo-1.png';
import image2 from '@/assets/images/offer-photo-2.png';
import image3 from '@/assets/images/offer-photo-3.png';
import image4 from '@/assets/images/offer-photo-4.png';

// Mark icon components as raw to prevent them from being reactive
const IconContactRaw = markRaw(IconContact);
const IconAvailabilityRaw = markRaw(IconAvailability);
const IconTourRaw = markRaw(IconTour);

const offersData = ref([
    {
        id: 1,
        date: 'NOVEMBER 20, 2023',
        category: 'Current Specials',
        title: 'Receive 1 month fee and a $750 gift card when you leased today',
        description: "Our exclusive offer is designed to provide you with an unbeatable deal for all our available floor plans. Act now and get one month of rent for free, and as an additional bonus, we'll also give you a $750 gift card. This is a limited-time promotion, so don't hesitate to take advantage of this incredible opportunity to save big on your new apartment in Northwest Houston.",
        offerValid: 'Prices subject to availability.',
        imageUrl: image1,
        actions: [
            { text: 'Contact Us', icon: IconContactRaw, link: '/' },
            { text: 'Check Availability', icon: IconAvailabilityRaw, link: '/' },
            { text: 'Schedule a Tour', icon: IconTourRaw, link: '/' }
        ]
    },
    {
        id: 2,
        date: 'NOVEMBER 27, 2023',
        category: 'AVAILABILITY ALERT',
        title: 'Immediate move-ins available now in NW Houston',
        description: "Looking for a brand new apartment in NW Houston to live in but don't have the time to wait? Don't worry, we've got you covered! Our selection of move-in-ready apartments is available now, so you can settle into your new home as soon as possible. Enjoy the convenience and ease of a stress-free move with our immediate move-in options.",
        offerValid: 'Prices subject to availability.',
        imageUrl: image2,
        actions: [
            { text: 'Contact Us', icon: IconContactRaw, link: '/' },
            { text: 'Check Availability', icon: IconAvailabilityRaw, link: '/' },
            { text: 'Schedule a Tour', icon: IconTourRaw, link: '/' }
        ]
    },
    {
        id: 3,
        date: 'DECEMBER 4, 2023',
        category: 'FEATURED FLOOR PLAN',
        title: 'Brand New NW Houston apartments starting at $1,350',
        description: "Discover the perfect home for you in our stylish apartments. Our one-bedroom apartments in Northwest Houston feature a spacious bedroom and a modern bathroom, fully equipped with the latest amenities. You'll love waking up in the morning and preparing your coffee in the fully functional kitchen, complete with granite countertops and stainless steel appliances. With prices starting from $1,350, you can enjoy all the comfort and convenience of a premium apartment without breaking the bank.",
        offerValid: 'Prices subject to availability.',
        imageUrl: image3,
        actions: [
            { text: 'Contact Us', icon: IconContactRaw, link: '/' },
            { text: 'Check Availability', icon: IconAvailabilityRaw, link: '/' },
            { text: 'Schedule a Tour', icon: IconTourRaw, link: '/' }
        ]
    },
    {
        id: 4,
        date: 'DECEMBER 11, 2023',
        category: 'FEATURED FLOOR PLAN',
        title: 'Luxury two-bedroom apartments starting at $1,805',
        description: "Our luxurious two-bedroom apartments in Northwest Houston come with open bedrooms, each fitted with its own private bathroom. These apartments offer a comfortable and sophisticated living experience, with prices starting at $1,805.",
        offerValid: 'Prices subject to availability',
        imageUrl: image4,
        actions: [
            { text: 'Contact Us', icon: IconContactRaw, link: '/' },
            { text: 'Check Availability', icon: IconAvailabilityRaw, link: '/' },
            { text: 'Schedule a Tour', icon: IconTourRaw, link: '/' }
        ]
    }
]);

// Function to parse and compare dates
const parseDate = (dateString) => new Date(dateString.toUpperCase());

// Computed property for sorted offers
const sortedOffers = computed(() => {
  return [...offersData.value].sort((a, b) => parseDate(b.date) - parseDate(a.date));
});

</script>
<template>
    <div class="offers-container">
        <OfferItem v-for="offer in sortedOffers" :key="offer.id" :offer="offer" />
    </div>
</template>

<style lang="scss" scoped>
.offers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
</style>