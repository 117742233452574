<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <g clip-path="url(#clip0_140_1731)">
            <path
                d="M7.75817 11.8703C7.85233 11.9644 7.97995 12.0173 8.11315 12.0173C8.24623 12.0173 8.37385 11.9645 8.46801 11.8703L10.2601 10.0783C10.4562 9.88217 10.4562 9.5644 10.2601 9.3683C10.0641 9.17232 9.7462 9.17232 9.55023 9.3683L8.11315 10.8054L7.45791 10.1501C7.2618 9.95415 6.94404 9.95415 6.74793 10.1501C6.55196 10.3461 6.55196 10.664 6.74793 10.8601L7.75817 11.8703Z"
                fill="white" />
            <path
                d="M8.50406 14.6042C10.7013 14.6042 12.4888 12.8165 12.4888 10.6193C12.4888 8.42204 10.7013 6.63452 8.50406 6.63452C6.30669 6.63452 4.51917 8.42204 4.51917 10.6193C4.51917 12.8165 6.30682 14.6042 8.50406 14.6042ZM8.50406 7.6384C10.1477 7.6384 11.4849 8.9756 11.4849 10.6193C11.4849 12.263 10.1477 13.6003 8.50406 13.6003C6.86037 13.6003 5.52304 12.263 5.52304 10.6193C5.52304 8.9756 6.86037 7.6384 8.50406 7.6384Z"
                fill="white" />
            <path
                d="M15.1852 1.06886H14.2697V0.501938C14.2697 0.22477 14.0449 0 13.7678 0C13.4906 0 13.2658 0.22477 13.2658 0.501938V1.06886H12.2794V0.501938C12.2794 0.22477 12.0547 0 11.7774 0C11.5002 0 11.2754 0.22477 11.2754 0.501938V1.06886H5.7326V0.501938C5.7326 0.22477 5.50783 0 5.23053 0C4.95336 0 4.72859 0.22477 4.72859 0.501938V1.06886H3.74223V0.501938C3.74223 0.22477 3.51746 0 3.24016 0C2.96299 0 2.73822 0.22477 2.73822 0.501938V1.06886H1.82254C0.927483 1.06886 0.199219 1.79712 0.199219 2.69231V15.3767C0.199219 16.2717 0.927483 17 1.82254 17H15.1852C16.0804 17 16.8086 16.2717 16.8086 15.3767V2.69231C16.8086 1.79712 16.0803 1.06886 15.1852 1.06886ZM15.8046 15.3767C15.8046 15.7182 15.5267 15.9961 15.1852 15.9961H1.82254C1.48104 15.9961 1.20309 15.7182 1.20309 15.3767V5.24272H15.8046V15.3767ZM1.20309 2.69231C1.20309 2.35068 1.48104 2.07286 1.82254 2.07286H2.73822V2.63978C2.73822 2.91695 2.96299 3.14172 3.24029 3.14172C3.51746 3.14172 3.74223 2.91695 3.74223 2.63978V2.07286H4.72859V2.63978C4.72859 2.91695 4.95336 3.14172 5.23066 3.14172C5.50783 3.14172 5.7326 2.91695 5.7326 2.63978V2.07286H11.2754V2.63978C11.2754 2.91695 11.5002 3.14172 11.7775 3.14172C12.0547 3.14172 12.2794 2.91695 12.2794 2.63978V2.07286H13.2658V2.63978C13.2658 2.91695 13.4906 3.14172 13.7678 3.14172C14.0451 3.14172 14.2698 2.91695 14.2698 2.63978V2.07286H15.1852C15.5267 2.07286 15.8047 2.35068 15.8047 2.69231V4.23872H1.20309V2.69231Z"
                fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_140_1731">
                <rect width="17" height="17" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>