<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="25" viewBox="0 0 23 25" fill="none">
        <path
            d="M8.88993 0.884059C8.07953 0.0978816 7.26913 -0.163252 6.44173 0.0978816C5.63132 0.339569 5.03628 1.02296 4.69625 2.15083C4.35622 3.25926 4.33638 4.48436 4.64241 5.81225C4.96544 7.15959 5.52365 8.2069 6.33405 8.97085C7.14445 9.73481 7.95485 9.99594 8.76526 9.75703C9.59266 9.51535 10.1877 8.83195 10.5277 7.70408C10.8706 6.59565 10.8876 5.37055 10.5816 4.04266C10.2585 2.69532 9.70034 1.64801 8.88993 0.884059Z"
            fill="white" />
        <path
            d="M16.0529 0.0978816C15.2425 -0.163252 14.4321 0.0978815 13.6217 0.884059C12.8113 1.67024 12.2361 2.71477 11.93 4.04266C11.607 5.38999 11.624 6.59843 11.9839 7.70408C12.3267 8.82917 12.9218 9.51534 13.7294 9.75703C14.5398 10.0182 15.3672 9.75703 16.1776 8.97085C16.988 8.2069 17.5462 7.15959 17.8522 5.81225C18.1752 4.48436 18.1582 3.25648 17.8154 2.15083C17.4555 1.02296 16.8605 0.339569 16.0501 0.0978816"
            fill="white" />
        <path
            d="M20.9693 9.07365C20.2864 8.73195 19.5298 8.81251 18.6996 9.35423C17.8892 9.89872 17.2403 10.7238 16.7728 11.8489C16.5744 12.3128 16.4497 12.774 16.3591 13.2379C16.2344 13.8824 16.2145 14.5047 16.3052 15.1103C16.4667 16.177 16.8804 16.8799 17.5662 17.2243C18.2689 17.6049 19.0255 17.5049 19.8359 16.9632C20.6633 16.4187 21.3121 15.5936 21.7797 14.4658C21.7967 14.4269 21.8165 14.3852 21.8335 14.3241C21.978 13.9602 22.0857 13.599 22.1735 13.2379C22.3181 12.535 22.3351 11.8683 22.2444 11.2044C22.1735 10.7016 22.029 10.2599 21.8307 9.91538C21.6153 9.53202 21.3263 9.25144 20.9664 9.07087"
        fill="white" />
    <path
        d="M17.2431 19.155C15.6761 16.1159 14.1658 14.1435 12.7065 13.2379C12.0746 12.8351 11.4456 12.635 10.8165 12.6545C10.1336 12.6739 9.46491 12.8767 8.81885 13.2379C8.80752 13.2379 8.79524 13.2444 8.78201 13.2573C7.35956 14.0602 5.99094 15.652 4.713 18.0049C3.79493 19.8162 3.36422 21.2247 3.41806 22.2525C3.4719 24.9694 5.21738 25.6334 8.65734 24.2249C8.71117 24.2055 8.74801 24.2055 8.78201 24.186C10.655 23.4415 12.0945 23.4999 13.1202 24.3666C14.146 25.2111 15.3531 25.2111 16.7755 24.3666C18.1271 23.2804 18.2887 21.5497 17.2431 19.155Z"
        fill="white" />
    <path
        d="M5.30555 10.1822C5.26871 10.121 5.23471 10.0599 5.21488 10.0016C4.6935 8.99317 4.02761 8.27089 3.21721 7.84863C2.4068 7.40693 1.68708 7.38471 1.05519 7.78752C0.426138 8.19033 0.0832754 8.89594 0.0124361 9.90158C-0.0584032 10.9072 0.17395 11.9129 0.695327 12.9185C1.2167 13.9436 1.88259 14.6909 2.693 15.1131C3.5034 15.5354 4.22312 15.5548 4.85218 15.1548C5.48123 14.752 5.84109 14.0464 5.89777 13.0407C5.91477 12.8602 5.91477 12.6574 5.89777 12.4768C5.86093 11.7128 5.66258 10.9461 5.30272 10.1822"
        fill="white" />
</svg></template>